<template>
    <div>
        <input class="form-control" type="text" v-model="query" @input="getUsernameList" Autocomplete="on" :list="param" :placeholder="$t('username')" :name="param" />
        <datalist :id="param" v-if="query">
            <option v-for="result in results" :value="result"></option>
        </datalist>
        <datalist :id="param" v-else>
            <option v-for="user in userList" :value="user"></option>
        </datalist>
    </div>
</template>

<script>
export default {
    props: ["param"],
    data() {
        return {
            query: document.getElementById('request_user').value,
            results: [],
            userList: {},
        };
    },
    mounted() {
        axios.get('/uploaded_file/user_top10')
        .then(response => {
            //console.log(response.data);
            this.userList = response.data;
        })
        .catch(error => {
            console.log(error);
        });
    },
    methods: {
        getUsernameList() {
            this.results = [];
            if (this.query.length > 1) {
                axios.get('/uploaded_file/datalist_user', {
                    params: { query: this.query }
                })
                .then(response => {
                    this.results = response.data;
                    //console.log(response);
                })
                .catch(error => {
                    console.log(error);
                });
            }
        }
    }
};
</script>